<template>
  <section class="view-audios py-5">
    <div class="container py-5">
      <div class="page-title">
        <h1>Todos os áudios</h1>
      </div>

      <action-dispatcher
        v-if="$user.cof && $user.cof.id && $user.hasCof"
        action="courses/getArchives"
        ref="actionDispatcher"
        :parameters="{
          id: $user.cof.id,
          params: parameters
        }"
      >
        <material-item
          v-for="(archive, index) in archives"
          :archive="archive"
          :key="index"
        />
      </action-dispatcher>

      <pagination
        class="pt-5"
        :count="archivesCount"
        :page="page"
        :limit="parameters.limit"
        @paginate="paginate"
      />
    </div>
  </section>
</template>

<script>
import Pagination from "@/mixins/Pagination";
import { mapState } from "vuex";
import MaterialItem from "@/components/materials/MaterialItem";

export default {
  components: { MaterialItem },
  mixins: [Pagination],
  data() {
    return {
      perPage: 20,
      extraParameters: {
        category: "audios"
      }
    };
  },
  computed: {
    ...mapState("courses", ["archives", "archivesCount"])
  },
  methods: {
    reload() {
      this.$refs.actionDispatcher.dispatch();
    }
  }
};
</script>

<style lang="scss" scoped>
.view-audios {
  .page-title {
    margin-bottom: 52px;

    h1 {
      margin-bottom: 0;
    }

    select {
      background: #ffffff;
      border: none;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      max-width: 160px;
    }
  }
}
</style>
