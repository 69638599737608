<template>
  <div class="material-item">
    <iframe
      v-if="archive.category_key === 'audios'"
      :src="archive.link"
      class="border-0 w-100 shadow-sm"
      :height="iframeHeight"
    ></iframe>
    <div v-else class="card shadow-sm">
      <div class="card-body d-flex align-items-center">
        <strong>{{ archive.name }}</strong>
        <div class="flex-fill"></div>
        <span class="text-secondary mr-5">{{ archive.category }}</span>
        <a
          :href="archive.file"
          target="_blank"
          class="btn btn-outline-primary btn-sm"
        >
          BAIXAR
          <i class="icon-download ml-1"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    archive: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    iframeHeight() {
      if (this.archive.link.indexOf("playlists") > 0) {
        return 450;
      }
      return 170;
    }
  }
};
</script>

<style lang="scss" scoped>
.material-item {
  margin: 16px 0;

  .card {
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5;

    .card-body {
      padding: 12px;
      padding-left: 24px;

      a {
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
</style>
