<script>
export default {
  data() {
    return {
      perPage: 10,
      extraParameters: {}
    };
  },
  computed: {
    page() {
      return this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    parameters() {
      return {
        limit: this.perPage,
        offset: (this.page - 1) * this.perPage,
        ...this.$route.query,
        ...this.extraParameters
      };
    }
  },
  watch: {
    "$route.query": {
      deep: true,
      handler() {
        setTimeout(() => {
          this.reload();
        }, 1);
      }
    }
  },
  methods: {
    paginate(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.extraParameters,
          page
        }
      });
    }
  }
};
</script>
